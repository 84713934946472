<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">进件列表</div>
      <el-form :inline="true" size="mini">
        <el-form-item label="业务申请编号">
          <el-input v-model="p.applyId" placeholder="精确查找"></el-input>
        </el-form-item>
        <el-form-item label="商户名">
          <el-input v-model="p.mchName" placeholder="精确查找"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="注册号">
          <el-input v-model="p.registrNumber" placeholder="精确查找"></el-input>
        </el-form-item>
        <el-form-item label="码队昵称">
          <el-input v-model="p.codeMchNickName" placeholder="模糊查找"></el-input>
        </el-form-item>
        <el-form-item style="min-width: 0px">
          <el-button type="primary" icon="el-icon-search" @click="
              p.page = 1;
              f5();
            ">查询</el-button>
        </el-form-item>
        <br />
      </el-form>

      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
              <el-table-column label="业务申请编号" prop="applyId" width="100px" />
              <el-table-column label="商户名" prop="mchName" width="180px" />
              <el-table-column label="注册号" prop="registrNumber" width="180px" />
              <el-table-column label="身份证姓名" prop="idName" width="80px"></el-table-column>
              <el-table-column label="状态" width="80px">
                <template slot-scope="s">
                  <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">审核中</b>
                  <b style="color: blue; margin-left: 10px" v-if="s.row.status == 0">未提交</b>
                  <b style="color: green; margin-left: 10px" v-if="s.row.status == 2">待签约</b>
                  <b style="color: red; margin-left: 10px" v-if="s.row.status == 3">已驳回</b>
                  <b style="color: green; margin-left: 10px" v-if="s.row.status == 4">已签约</b>
                </template>
              </el-table-column>
              <el-table-column label="所属码队" prop="codeMchNickName" width="100px" />
              <el-table-column label="商户号" prop="mch" width="100px" :formatter="remarksFormat" />
              <el-table-column label="服务商" prop="providerName" width="100px" />
              <el-table-column label="创建时间" prop="providerName" width="100px">
                <template slot-scope="s">
                  <b style="margin-left: 10px">{{
                    sa.forDate2(s.row.createdTime, "")
                  }}</b>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作" width="320px" fixed="right">
                <template slot-scope="s">
                  <el-button class="c-btn" v-if="s.row.status == 2" type="success" icon="el-icon-thumb" @click="showImage(s.row)">签约</el-button>
                  <el-button class="c-btn" v-if="s.row.status == 4" type="success" icon="el-icon-thumb" @click="open(s.row)">修改结算信息</el-button>
                  <el-button class="c-btn" v-if="s.row.status == 4" type="success" icon="el-icon-thumb" @click="checkModifySettlementStatus(s.row)">查询修改状态</el-button>
                  <el-button class="c-btn" v-if="s.row.status == 3 || s.row.status == 0" type="success" icon="el-icon-edit" @click="edit(s.row)">编辑</el-button>
                  <el-button class="c-btn" v-if="s.row.status == 3" type="danger" icon="el-icon-view" @click="showMsg(s.row)">查看驳回消息</el-button>
                  <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="page-box">
              <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <ApplymentModifySettlement ref="applyment-modify-settlement"></ApplymentModifySettlement>
  </div>
</template>

<script>
import ApplymentModifySettlement from "./applyment-modify-settlement.vue";
export default {
  components: { ApplymentModifySettlement },
  data() {
    return {
      p: {
        // 查询参数
        applyId: "",
        mchName: "",
        registrNumber: "",
        codeMchNickName: "",
        page: 1,
        limit: 10,
      },
      dataCount: 1422,
      dataList: [],
    };
  },
  methods: {
    open: function (data) {
      this.$refs["applyment-modify-settlement"].open(data);
    },
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.sa_admin.role == 3) {
        this.p.mchId = this.sa_admin.mchId;
      }
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/getApplyInfo",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    remarksFormat(row) {
      if (row.mch == "" || null) {
        return "未获取到";
      } else {
        return row.mch;
      }
    },
    checkModifySettlementStatus(data) {
      var defaultCfg = {
        type: "get", //是否是请求体请求
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/modify-settlement/application/" + data.applyId,
        function (res) {
          this.sa.ok(res.data);
        }.bind(this),
        defaultCfg
      );
    },
    showMsg: function (data) {
      this.sa.alert(data.signUrl);
    },
    edit: function (data) {
      this.sa.setCookie("applyment_id", data.applyId, 1);
      this.sa_admin.showMenuById("applyment");
      var page = this.sa_admin.getView("applyment");
      if (page != null) {
        page.intApply();
      }
    },
    // 删除
    del: function (data) {
      var defaultCfg = {
        type: "get", //是否是请求体请求
      };
      this.sa.confirm(
        "是否删除，此操作不可撤销",
        function () {
          this.sa.ajax(
            this.sa.cfg.provider_api_url + "/apply/deleteApply/" + data.applyId,
            function () {
              this.sa.arrayDelete(this.dataList, data);
              this.sa.ok("删除成功");
            }.bind(this),
            defaultCfg
          );
        }.bind(this)
      );
    },
    showImage: function (res) {
      this.sa.showImage(
        this.sa.cfg.api_url +
        "/v1/index/enQrcode?url=" +
        encodeURIComponent(res.signUrl),
        "250px",
        "250px"
      );
    }
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>

<style scoped>
/* /deep/ .el-autocomplete-suggestion {
  width: auto!important;
}
.el-select > .el-select-dropdown {
  transform:translateX(-100px);
} */

/deep/.el-autocomplete-suggestion {
  position: absolute !important;
  top: 50px !important;
  left: 0 !important;
  width: auto !important;
}

/deep/ .c-panel .el-form .el-input {
  width: 217px;
}
</style>
<template>
  <el-dialog :title="this.id == 0 ? '修改结算信息' : '修改结算信息'" :visible.sync="isShow" width="650px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <el-row type="flex" justify="center" align="middle">

      <el-form style="margin-top: 50px" ref="form" v-if="active==0" label-width="140px">
        <el-form-item label="账户类型:">
          <el-select @change="clearBank" v-model="step4.accountType">
            <el-option label="对私账户" :value="75"></el-option>
            <el-option label="对公账户" :value="74"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开户银行:">
          <el-select filterable v-el-select-loadmore="loadmore" v-model="step4.bankName" @change="changeBank">
            <el-option v-for="item in banklist" :key="item.bank_alias" :label="item.bank_alias" :value="item.bank_alias + '|' + item.need_bank_branch" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="need_bank_branch == 'true'" label="开户银行全称:">
          <el-input v-model="step4.bankSubName" style="width: 100%" placeholder="开户银行全称"></el-input>
        </el-form-item>
        <el-row>
          <el-form-item label="开户银行省市编码:">
            <el-autocomplete style="width: 350px" v-model="keyword" :popper-append-to-body="false" :fetch-suggestions="querySearchAsyncAddressCode" popper-class="el-autocomplete-suggestion" placeholder="请输入内容" @select="handleSelect" :trigger-on-focus="false"></el-autocomplete>
          </el-form-item>
        </el-row>
        <el-form-item label="银行账号:">
          <el-input v-model="step4.bankUsername" style="width: 350px" placeholder="银行账号"></el-input>
        </el-form-item>
        <el-form-item label="开户名称:">
          <el-input v-model="step4.accountName" style="width: 350px" placeholder="开户名称"></el-input>
        </el-form-item>

        <el-form-item>
          <span class="c-label"><el-button size="small" style="margin-top: 12px" @click="ok()">提交修改</el-button></span>

          <span class="c-label"><el-button size="small" style="margin-left: 12px" @click="(isShow = false), cleanData(step4)">取 消</el-button></span>
        </el-form-item>
      </el-form>
    </el-row>
  </el-dialog>
</template>

<script>
export default {

  data() {
    return {
      id: 0,
      isShow: false,
      applyData: {},
      active: 0,
      providerlists: [],
      bankoffset: 0,
      banklist: [],
      addresslist: [],
      keyword: "",
      need_bank_branch: true,
      p: {
        provider_id: "",
      },
      //第一步所需参数
      step4: {
        accountType: "",
        bankName: "",
        bankSubName: "",
        addressCode: "",
        bankUsername: "",
        accountName: ""
      },
    };
  },
  methods: {
    open: function (data) {
      this.isShow = true;
      console.log(data)
      this.applyData = data;
    },

    ok: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      //设置商户号
      var p = {
        subMchid: this.applyData.mch,
        account_type: this.step4.accountType,
        account_bank: this.step4.bankName,
        bank_address_code: this.step4.addressCode,
        bank_name: this.step4.bankSubName,
        account_number: this.step4.bankUsername,
        account_name: this.step4.accountName,
      };
      let self = this;
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/modify-settlement/" + this.applyData.applyId,
        p,
        function () {
          self.sa.ok("提交申请成功", function () {
            self.isShow = false;
          });
        }.bind(this),
        defaultCfg
      );
    },
    getServerProvider: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        sleep: 50,
        msg: null,
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/provider/list",
        function (res) {
          this.providerlists = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    changeBank: function (data) {
      let [name, need_bank_branch] = data.split("|");
      this.step4.bankName = name;
      this.need_bank_branch = need_bank_branch;
    },
    handleBusinessImgIdSuccess: function (res) {
      var str = JSON.parse(res.data);
      this.step2.businessImgId = str.media_id;
      console.log(this.step2);
    },
    handleIdFaceImgIdSuccess: function (res) {
      var str = JSON.parse(res.data);
      this.step2.idFaceImgId = str.media_id;
      console.log(this.step2);
    },
    handleIdCountryImgIdSuccess: function (res) {
      var str = JSON.parse(res.data);
      this.step2.idCountryImgId = str.media_id;
      console.log(this.step2);
    },
    querySearchBankName: function (offset) {
      var defaultCfg = {
        type: "get",
        msg: null,
        sleep: 50,
      };
      var page = {
        limit: 10,
        offset: offset,
      };
      let url = null;
      if (this.step4.accountType == 74) {
        url = this.sa.cfg.provider_api_url + "/bank/corporate-banking";
      } else {
        url = this.sa.cfg.provider_api_url + "/bank/personal-banking";
      }
      this.sa.ajax(
        url,
        page,
        function (res) {
          var data = JSON.parse(res.data);
          console.log(data);
          this.sa.pushArray(this.banklist, data.data);
        }.bind(this),
        defaultCfg
      );
    },
    cleanData: function (data) {
      Object.keys(data).forEach((key) => {
        data[key] = "";
      });
    },
    querySearchAsyncAddressCode: function (queryString, cb) {
      var defaultCfg = {
        type: "get",
        msg: null,
        sleep: 50,
      };
      var page = {
        address: queryString,
      };
      if (this.keyWord != "") {
        this.sa.ajax(
          this.sa.cfg.provider_api_url + "/bank/addressCode",
          page,
          function (res) {
            console.log(res);
            let addresslist = res.data;
            addresslist = addresslist.map((item) => {
              return { value: item.address, id: item.id };
            });
            cb(addresslist);
          }.bind(this),
          defaultCfg
        );
      }
    },
    async handleSelect(val) {
      console.log(val);
      this.step4.addressCode = val.id;
    },
    loadmore() {
      this.bankoffset = this.bankoffset + 10;
      this.querySearchBankName(this.bankoffset);
    },
    clearBank() {
      this.banklist = [];
      this.querySearchBankName(0);
      this.step4.bankName = "";
      this.$forceUpdate();
    },
  },
  created() {

  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>


